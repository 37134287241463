<template>
  <div class="home content">
    <h1>Selected Works</h1>
    <youtube video-id="leBa5wKjyYk" />
  </div>
</template>

<script>

export default {
  name: 'home',
};
</script>

<style scoped lang="scss">
.home {
  margin-bottom: 24px;
}
</style>
