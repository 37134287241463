<template>
  <div class="footer">
    <div class="break" />
    <div class="social">
      <a href="https://www.facebook.com/profile.php?id=100007384569010" target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22 0H2C0.895 0 0 0.895 0 2V22C0 23.105 0.895 24 2 24H13V15H10V11H13V8.413C13 5.313 14.893 3.625 17.659 3.625C18.984 3.625 20.122 3.724 20.454 3.768V7.008L18.536 7.009C17.032 7.009 16.741 7.724 16.741 8.772V11H21.181L20.181 15H16.741V24H22C23.105 24 24 23.105 24 22V2C24 0.895 23.105 0 22 0Z" />
        </svg>
      </a>
    </div>
    <div class="text">
      © 2018 Gus the Beagle. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid white;
}
.social {
  margin-top: 16px;
  svg {
    fill: white;
    &:hover {
      fill: #9E9E9E;
    }
  }
}
.text {
  font-size: 14px;
  margin: 12px 0;
}
</style>
