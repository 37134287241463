<template>
  <div class="hero">
    <h1>Gus Pullman</h1>
    <h2>The Life and Times</h2>
  </div>
</template>

<script>
export default {
  name: 'Hero',
};
</script>

<style scoped lang="scss">
.hero {
  margin-bottom: 12px;
}
h1 {
  margin-top: 56px;
  margin-bottom: 24px;
  font-size: 44px;
}
h2 {
  font-size: 20px;
}
</style>
